<template>
  <subscriptions-wrapper v-if="subscriptions" v-slot="{ activeSubscriptions }" :subscriptions="subscriptions">
    <div class="flex flex-col">
      <div class="flex">
        <app-logo v-hubble="'app-logo'" class="h-12 shrink-0 w-12" />

        <div class="flex flex-col h-full justify-center ml-2 space-y-2 w-full">
          <p v-hubble="'org-name'" class="font-bold leading-4 text-white">{{ $auth.user.orgName }}</p>
          <div class="flex space-x-2">
            <subscription-badge
              v-for="subscription in activeSubscriptions"
              :key="`${subscription.plan}_badge`"
              :plan="subscription.plan"
              class="self-start shrink"
            />
          </div>
        </div>
      </div>

      <div v-for="subscription in activeSubscriptions" :key="`${subscription.plan}_expiration-card`">
        <div
          v-if="showManagePlanCard(subscription)"
          class="border flex h-[66px] items-center justify-center mt-4 px-5 py-4 rounded-lg w-full"
          :class="[subscription.cardPalette.textColorDark, subscription.cardPalette.borderColorDark]"
        >
          <div class="flex flex-grow items-center">
            <cx-icon name="timer-line" size="xl" />

            <span class="font-bold ml-2 text-sm">{{ subscription.daysLeftText }}</span>
          </div>

          <nuxt-link
            to="/settings/plan-details/your-plan"
            class="border flex-grow font-medium ml-4 py-2 rounded-lg text-center text-xs"
            :class="subscription.cardPalette.borderColorDark"
          >
            {{ $t('managePlan') }}
          </nuxt-link>
        </div>
      </div>
    </div>
  </subscriptions-wrapper>
</template>

<script>
import AppLogo from '~/components/common/AppLogo';
import { CxIcon } from '~/components/cx';
import SubscriptionBadge from '~/components/subscription/SubscriptionBadge';
import SubscriptionsWrapper from '~/components/subscription/SubscriptionsWrapper';
import { ENTITLEMENTS, SUBSCRIPTION_PLANS } from '~/support/constants';

export default {
  name: 'AppSidebarSubscriptionCard',

  hubble: 'app-sidebar-subscription-card',

  components: { AppLogo, SubscriptionBadge, CxIcon, SubscriptionsWrapper },

  computed: {
    canViewPlanDetails() {
      return this.$hasEntitlement(ENTITLEMENTS.plan.view) && this.$flag('showSubscriptionPlans');
    },

    subscriptions() {
      return this.$auth.user.subscriptions;
    },
  },

  methods: {
    showManagePlanCard(subscription) {
      return (
        this.canViewPlanDetails && subscription.shouldShowDaysLeft && subscription.plan !== SUBSCRIPTION_PLANS.free
      );
    },
  },
};
</script>

<i18n lang="json">
{
  "en": {
    "managePlan": "Manage Plan"
  }
}
</i18n>
